import { render, staticRenderFns } from "./FormulariosPorTarifario.vue?vue&type=template&id=a53bbf9c&scoped=true&"
import script from "./FormulariosPorTarifario.vue?vue&type=script&lang=js&"
export * from "./FormulariosPorTarifario.vue?vue&type=script&lang=js&"
import style0 from "./FormulariosPorTarifario.vue?vue&type=style&index=0&id=a53bbf9c&scoped=true&lang=css&"
import style1 from "./FormulariosPorTarifario.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a53bbf9c",
  null
  
)

export default component.exports